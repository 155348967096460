import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core"
import { Send } from "@material-ui/icons"
import Swal from "sweetalert2"

import ReCAPTCHA from "react-google-recaptcha";
import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useRef,
  useState,
} from "react"
import Axios from 'axios';
import { validateCaptcha } from '../../utils/suggestions-mailbox/Functions'
import AreaIsset from "../../classes/AreaIsset"
import Buzon from "../../classes/Buzon"
import Direccion from "../../classes/Direccion"
import TipoBuzon from "../../classes/TipoBuzon"
import AuxiliarInput from "../../components/AuxiliarInput"
interface ISuggestionStepProps {
  direcciones: Direccion[]
  areas: AreaIsset[]
  buzon: Buzon
  tipos: TipoBuzon[]
  setBuzon: Dispatch<SetStateAction<Buzon>>
  previousStep: () => void
  send: () => void
}
const SuggestionStep: FunctionComponent<ISuggestionStepProps> = ({
  direcciones,
  areas,
  tipos,
  buzon,
  setBuzon,
  previousStep,
  send,
}) => {
  const [recaptchaResponse, setRecaptchaResponse] = useState("");
  const [idDireccion, setIdDireccion] = useState<number>()

  const handleUpdateBuzon = (key: string, value: any) => {
    const buz = buzon
    buz[key] = value
    setBuzon({ ...buz })
  }


  const recaptchaRef = React.createRef<ReCAPTCHA>();
  if (!buzon) return <></>
  return (
    <Grid container spacing={2} className="sug-mail__content__suggestion">

      <Grid item xs={12} md={4}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Asunto</FormLabel>
          <RadioGroup
            aria-label="asunto"
            value={buzon.idTipo || null}
            onChange={e => handleUpdateBuzon("idTipo", Number(e.target.value))}
          >
            {tipos.map(tipo => (
              <FormControlLabel
                key={tipo.idTipo}
                label={tipo.nombre}
                value={tipo.idTipo}
                control={<Radio color="primary" />}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid container item xs={12} md={8}>
        <AuxiliarInput
          label="Dirección de la que se expresa"
          required
          select
          items={direcciones.map(d => ({
            id: d.idDireccion,
            name: d.nombre,
          }))}
          value={idDireccion}
          onChange={e => setIdDireccion(Number(e.target.value))}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        />
        <AuxiliarInput
          label="Área de la que se expresa"
          required
          select
          items={areas
            .filter(a => a.idDireccion === idDireccion)
            .map(a => ({
              id: a.idArea,
              name: a.nombre,
            }))}
          value={buzon.idArea}
          onChange={e => handleUpdateBuzon("idArea", Number(e.target.value))}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        />
        <AuxiliarInput
          xs={12}
          sm={12}
          md={12}
          lg={12}
          value={buzon.personalImplicado}
          onChange={e => handleUpdateBuzon("personalImplicado", e.target.value)}
          label="Personal implicado"
          multiline
          rows={3}
          maxLength={200}
          required
        />
      </Grid>



      <Grid item xs={12}>
        <AuxiliarInput
          xs={12}
          sm={12}
          md={12}
          lg={12}
          value={buzon.expresion}
          onChange={e => handleUpdateBuzon("expresion", e.target.value)}
          label="Expresión ciudadana"
          multiline
          rows={3}
          maxLength={800}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_G_SITE_KEY}
          onChange={(value) => setRecaptchaResponse(value || "")}
          onExpired={() => {setRecaptchaResponse(""), recaptchaRef.current.reset()}}
        />

      </Grid>
      <Grid
        item
        xs={12}
        className="sug-mail__content__suggestion__action-buttons"
      >
        <Button color="primary" variant="outlined" onClick={previousStep}>
          Regresar
        </Button>





        <Button color="primary" variant="contained" onClick={async () => {
          const source = Axios.CancelToken.source();
          const validated = await validateCaptcha(recaptchaResponse, source.token)
          if (!validated) return Swal.fire({
            title: "Error de reCAPTCHA",
            text: "La prueba de reCAPTCHA no fue superada",
            icon: "error",
          });
          send()
          setRecaptchaResponse("")
        }} disabled={recaptchaResponse.length > 0 ? false : true}>
          Enviar &ensp;<Send />
        </Button >
      </Grid>
    </Grid>
  )
}

export default SuggestionStep
