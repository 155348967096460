import React, { useState } from "react"
import {
  Grid,
  GridSize,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core"
import { FunctionComponent } from "react"
import "../assets/styles/auxiliar-input.scss"

interface IMyInputProps {
  label: string
  value: any
  onChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => any
  type?: "string" | "date" | "number" | "email" | "tel"
  required?: boolean
  select?: boolean
  items?: { id: number; name: string }[]
  disabled?: boolean
  placeholder?: string
  loading?: boolean
  xs?: GridSize
  sm?: GridSize
  md?: GridSize
  lg?: GridSize
  multiline?: boolean
  rows?: number
  maxLength?: number
}

const AuxiliarInput: FunctionComponent<IMyInputProps> = ({
  value,
  onChange,
  label,
  type = "string",
  required = false,
  select = false,
  items = [],
  disabled = false,
  placeholder,
  loading = false,
  xs = 12,
  sm = 6,
  md = 4,
  lg = 4,
  multiline = false,
  rows = 1,
  maxLength,
}) => {
  const [charactersCount, setCharactersCount] = useState(0)

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setCharactersCount(e.target.value.length)
    onChange(e)
  }

  if (select && !items)
    throw new Error("If select is true, an array of items is required!")
  if (loading)
    return (
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          disabled={true}
          value={"Cargando..."}
        />
      </Grid>
    )
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} className="aux-input">
      <TextField
        variant="outlined"
        fullWidth
        margin="dense"
        label={label}
        value={value || ""}
        onChange={handleChange}
        type={type}
        InputLabelProps={{
          shrink: type === "date" || !!value,
        }}
        required={required}
        placeholder={placeholder}
        select={select}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        inputProps={{
          maxLength: maxLength,
        }}
      >
        {select &&
          items.map(item => (
            <MenuItem value={item.id} key={item.id}>
              {item.name}
            </MenuItem>
          ))}
      </TextField>
      {maxLength && (
        <Typography
          color="textSecondary"
          variant="caption"
          className="aux-input__counter"
        >
          {charactersCount}/{maxLength}
        </Typography>
      )}
    </Grid>
  )
}

export default AuxiliarInput
