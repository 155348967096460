import { CancelToken } from "axios";
import { axiosPortal } from "../../utils/constants";
import { handleAxiosError } from "../../utils/functions";

export const validateCaptcha = async (recaptchaResponse: string, cancelToken: CancelToken) => {
    try {
      const url = `/recaptcha`;
      const resp = await axiosPortal.post(url, {
        cancelToken,
        response: recaptchaResponse
      });
      return resp.data.success ? true: false
    } catch (error) {
      if (!cancelToken) handleAxiosError(error);
    }
  }